.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  }
  .pagination a {
    color: #2C98F0;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #2C98F0;
    margin: 0 4px;
    border-radius: 5px;
  }
  .pagination a.active {
    background-color: #2C98F0;
    color: white;
    border: 1px solid #2C98F0;
  }
  .pagination a.dots {
    color: #2C98F0;
  }
 
  @media (max-width:767px)
  {
    .pagination {
      margin-bottom: 21px;
  }
  }