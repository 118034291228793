body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
  margin: 0 auto !important;
}
.modal.show {
  position: fixed;
  background: #0000008e;
}
.modal-header {
  padding: 0 !important;
  border: 0 !important;
  text-align: right;
}
.modal-header button {
  background: #2C98F0;
  color: #fff;
  border: 0;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  font-size: 23px;
  float: right;
  position: absolute;
  right: -14px;
  top: -12px;
  z-index: 9;
}
.berifymodal-heading {
  padding: 12px 0;
  /* margin: 21px auto 0; */
}
.modal-header.opt-header {
  border-bottom: 1px solid #ddd !important;
  padding: 0 20px !important;
}
.modal-header.opt-header button {
  opacity: 1;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  padding: 1px 0 0;
  font-size: 15px;
  color: #fff !important;
}
/* .Toastify {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 30px;
  min-width: 430px;
  z-index: 9;
  border-radius: 10px;
} */
a.navbar-brand.text-center {
  width: 100%;
}
/* .Toastify__toast-body {
  display: flex;
  align-items: center;
}
.Toastify__toast-body svg {
  width: 30px;
  margin-right: 9px;
  fill: #049100;
} */

/* button.Toastify__close-button.Toastify__close-button--light {
  display: none;
} */
.search-suggestion {
  color: #fff;
  border: 0;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
}
 .showSuggestion {
  display: block;
 }
 .hideSuggestion {
  display: none;
 }
 .select-itme {
  padding: 5px;
  border-bottom: 1px solid grey;
  cursor: pointer;
}
 

.search-suggestion>p {
  margin-top: 5 !important;
}
